<template>
  <div v-if="scope.row.scale_type">
    <div
      class="element-scale-config"
      :class="{editable: isFieldAvailableForEdit(scope.row, 'scale')}"
      @click="$refs.editScaleDialog.open(scope.row)"
    >
      <component
        :is="scaleTemplate"
        :data="scope.row.scale"
      ></component>
    </div>
    <card-element-scale-edit-dialog
      ref="editScaleDialog"
      @save-clicked="saveElementScaleData"
    >
    </card-element-scale-edit-dialog>
  </div>
  <div v-else style="color: #b1b1b2;">Сначала укажи тип цели</div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import CardElementScaleEditDialog from "@/components/cards/CardElementsBlock/CardElementScaleEditDialog";

export default {
  name: 'matrix-scale-config-table-column',
  components: {CardElementScaleEditDialog, ClickToEdit},

  props: {
    scope: {type: Object, required: true},
    isFieldAvailableForEdit: {type: Function, required: true},
    saveElementPropChange: {type: Function, required: true},
  },
  data() {
    return {}
  },
  computed: {
    scaleTemplate() {
      if( !this.scope.row.scale_type ){
        return null;
      }
      return this.$companyConfiguration.scales.getScaleTemplate(this.scope.row.scale_type, 'cardView');
    }
  },
  methods: {
    saveElementScaleData(element) {
      this.saveElementPropChange(element, 'scale');
      this.$refs.editScaleDialog.close();
    },
  }
}

</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.element-scale-config {
  padding: 2px 5px;
  cursor: pointer;

  &.editable {
    border: $--editable-border;
    border-radius: $--border-radius-base;
  }

  .card-view-divider {
    margin:0;
  }
}
</style>
